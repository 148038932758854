import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyByOBw0vXYQ_I0GUEF_aRxKNnbZL5ZxDTM",
    authDomain: "smartchauffeur-52914.firebaseapp.com",
    databaseURL: "https://smartchauffeur-52914.firebaseio.com",
    projectId: "smartchauffeur-52914",
    storageBucket: "smartchauffeur-52914.appspot.com",
    messagingSenderId: "565320104128",
    appId: "1:565320104128:web:d7152ed4b3d85fe72a9525",
    measurementId: "G-NCYN9XFFGB"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};