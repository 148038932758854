import React, { Component } from 'react'
import {AboutUsContent} from '../../content';

export default class About extends Component {
    render() {
        return (
            <div className="container mt-5 mb-5 pageHeightBody">
                <div className="row justify-content-center">
                    <div className="col-6">
                        <h2 className="text-center">{AboutUsContent.title}</h2>

                        <p>{AboutUsContent.para1}</p>
                        <p>{AboutUsContent.para2}</p>
                        {/* <p>{AboutUsContent.para3}</p>
                        <p>{AboutUsContent.para4}</p> */}
                    </div>
                </div>
            </div>
        )
    }
};