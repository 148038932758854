const authList = [
    "hewage18@gmail.com",
    "chathuramaduwantha99@gmail.com",
    "necorohana@gmail.com",
    "nipuninadeeshani07@gmail.com",
]

const smsToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNzI5ZGYyMC0xMmI2LTExZWItYjUwOS04NTY4ZDg4YTcwNjEiLCJzdWIiOiJTSE9VVE9VVF9BUElfVVNFUiIsImlhdCI6MTYwMzE4NTg4NCwiZXhwIjoxOTE4NzE4Njg0LCJzY29wZXMiOnsiYWN0aXZpdGllcyI6WyJyZWFkIiwid3JpdGUiXSwibWVzc2FnZXMiOlsicmVhZCIsIndyaXRlIl0sImNvbnRhY3RzIjpbInJlYWQiLCJ3cml0ZSJdfSwic29fdXNlcl9pZCI6IjY5NjMiLCJzb191c2VyX3JvbGUiOiJ1c2VyIiwic29fcHJvZmlsZSI6ImFsbCIsInNvX3VzZXJfbmFtZSI6IiIsInNvX2FwaWtleSI6Im5vbmUifQ.OW3han0bhOLe8IZRNQFTL0mfAgNholrOT6_NgPWrTVs";

export {
    authList,
    smsToken
}