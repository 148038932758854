import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import SignIn from './screens/auth/signin';
import Home from './screens/home';
import About from './screens/about';
import Packages from './screens/packages'
import Layout from './components/layout'

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/aboutus">
            <About />
          </Route>
          <Route path="/packages">
            <Packages />
          </Route>
          <Route path="/admin">
            <SignIn />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
