import React, { Component } from 'react'
import {FooterContent, HeroContent} from '../../content'

export default class Footer extends Component {
    render() {
        const year = new Date().getFullYear();

        return (
            <footer className="container">
                <div className="float-right">
                    <p className="mb-1"><a href={`mailto: ${FooterContent.email}`} className="text-dark text-decoration-none">{FooterContent.email}</a></p>
                    <p><a href={`tel: ${HeroContent.number}`} className="text-dark text-decoration-none">{HeroContent.number}</a></p>
                </div>
                <p>&copy; {year} Smart Chauffeur. </p>
            </footer>
        )
    }
}
