import React, { Component } from 'react'
import {PackageContent} from '../../content';

export default class Packages extends Component {
    render() {
        return (
            <div className="container mt-5 mb-5 pageHeightBody">
                    <h2>{PackageContent.title}</h2>

                    <div className="row justify-content-center mt-5 mb-5">
                        <div class="col-md-4 justify-content-md-center">
                            <h2>{PackageContent.package1.title}</h2>
                            <p>{`Startup fee - ${PackageContent.package1.price}`}</p>
                            <p>{`Per KM charge - ${PackageContent.package1.KMs}`}</p>
                            <p>{`Waiting charge - ${PackageContent.package1.waiting}`}</p>
                            <p className="mt-2">{PackageContent.package1.waitingCond}</p>
                        </div>
                    </div>
            </div>
        )
    }
}
