import React, { Component } from 'react'
import ShoutoutClient from 'shoutout-sdk';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AdminContent} from '../../content';
import {smsToken} from '../../config';

let debug = true, verifySSL = false;
 
let client = new ShoutoutClient(smsToken, debug, verifySSL);

const senderID = "SMchauffeur";

export default class Admin extends Component {
    state = {
        number: "",
        distance: "",
        charge: "",
        reqTime: "",
        cName: "",
        cNumber: "",
        bulk: "",
        text: "",
        isLoaderVisible: false,
    }

    handleBookingConfirm() {
        const {number, distance, charge, reqTime, cName, cNumber} = this.state;

        this.setState({isLoaderVisible: true})

        const smsBody = `Your booking has been confirmed with Smart Chauffeur. Your chauffeur name: ${cName}. Chauffeur number: ${cNumber}. Pickup time: ${reqTime} Thank you!`;
        
        var message = {
            source: senderID,
            destinations: [number],
             content: {
                 sms: smsBody
            },
             transports: ['sms']
          };

          client.sendMessage(message, (error, result) => {
            if (error) {
                console.error('error ', error);
                toast.error("Error, ", error); 
            } else {
                toast.success("Booking confirmation Sent!"); 
            }

            this.setState({isLoaderVisible: false});
          });
    }

    handleBookingDispath() {
        const {number, distance, charge, reqTime, cName, cNumber} = this.state;

        this.setState({isLoaderVisible: true})

        const smsBody = `Knock! Knock! your chauffeur arrived! Your ride with Smart Chauffeur will start at ${reqTime}. Safe Journey!`;
        
        var message = {
            source: senderID,
            destinations: [number],
             content: {
                 sms: smsBody
            },
             transports: ['sms']
          };

          client.sendMessage(message, (error, result) => {
            if (error) {
                console.error('error ', error);
                toast.error("Error, ", error); 
            } else {
                toast.success("Dispatch confirmation Sent!"); 
            }

            this.setState({isLoaderVisible: false});
          });
    }

    handleBookingUpdate() {
        const {number, distance, charge, reqTime, cName, cNumber} = this.state;

        this.setState({isLoaderVisible: true})

        const smsBody = `You have arrived at your destination. Your fair is Rs.${charge} for ${distance}Km. Thank you for choosing Smart Chauffeur and do take care till next time!`;
        
        var message = {
            source: senderID,
            destinations: [number],
             content: {
                 sms: smsBody
            },
             transports: ['sms']
          };

          client.sendMessage(message, (error, result) => {
            if (error) {
                console.error('error ', error);
                toast.error("Error, ", error); 
            } else {
                toast.success("Booking Update Sent!"); 
            }

            this.setState({isLoaderVisible: false});
          });
    }

    handleBulkSMS() {
        let hasBulk = false;
        let numArr = [];
        const {bulk, text} = this.state;

        this.setState({isLoaderVisible: true})

        if(bulk) {
            hasBulk = true;
            let arr = [];

            let blkNumList = bulk.replace(/ /g,'')
            blkNumList = blkNumList.replace(/(\r\n|\n|\r)/gm, "");

            arr = blkNumList.split(",");
            numArr = arr.filter(n => n);
        }

        var message = {
            source: senderID,
            destinations: numArr,
             content: {
                 sms: text
            },
             transports: ['sms']
          };

          if(hasBulk) {
            client.sendMessage(message, (error, result) => {
                if (error) {
                    console.error('error ', error);
                    toast.error("Error, ", error); 
                } else {
                    toast.success("Bulk SMS Sent!"); 
                }
    
                this.setState({isLoaderVisible: false});
            });
          }

    }

    render() {
        const {number, distance, charge, reqTime, cName, cNumber, isLoaderVisible, bulk, text} = this.state;

        return (
            <div className="container mt-5 mb-5">
                <div className="row justify-content-center">
                    <div className="col-6">
                        <h2 className="text-center">{AdminContent.pageTitle}</h2>

                        <form>
                            <div class="form-group">
                                <label htmlFor="exampleInputEmail1">Number</label>
                                <input type="text" class="form-control" placeholder="Number with 94" value={number} onChange={(e) => this.setState({number: e.target.value})}/>
                            </div>
                            <div class="form-group">
                                <label htmlFor="exampleInputEmail1">Requested Time</label>
                                <input type="text" class="form-control" placeholder="Requested time" value={reqTime} onChange={(e) => this.setState({reqTime: e.target.value})}/>
                            </div>
                            <div class="form-group">
                                <label htmlFor="exampleInputEmail1">Chauffeur Name</label>
                                <input type="text" class="form-control" placeholder="Chauffeur Name" value={cName} onChange={(e) => this.setState({cName: e.target.value})}/>
                            </div>
                            <div class="form-group">
                                <label htmlFor="exampleInputEmail1">Chauffeur Number</label>
                                <input type="text" class="form-control" placeholder="Chauffeur Number" value={cNumber} onChange={(e) => this.setState({cNumber: e.target.value})}/>
                            </div>
                            <div class="form-group">
                                <label htmlFor="exampleInputEmail1">Distance</label>
                                <input type="text" class="form-control" placeholder="Distance" value={distance} onChange={(e) => this.setState({distance: e.target.value})}/>
                            </div>
                            <div class="form-group">
                                <label htmlFor="exampleInputEmail1">Charge</label>
                                <input type="text" class="form-control" placeholder="Charge" value={charge} onChange={(e) => this.setState({charge: e.target.value})}/>
                            </div>
                            <p>For Bulk SMS</p>
                            <div class="form-group">
                                <label htmlFor="exampleInputEmail1">SMS Body</label>
                                <input type="text" class="form-control" placeholder="Bulk SMS body" value={text} onChange={(e) => this.setState({text: 
                                e.target.value})}  />
                            </div>
                            <textarea class="form-control" rows="5" placeholder="Add bulk SMS Numbers with 94 extension and , seperate" value={bulk} onChange={(e) => this.setState({bulk: e.target.value})}></textarea>
                            <div className="row mt-3">
                                {
                                    (isLoaderVisible)?
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <>
                                        <button type="button" class="btn btn-primary mr-4" onClick={() => this.handleBookingConfirm()}>Confirm</button>
                                        <button type="button" class="btn btn-primary mr-4" onClick={() => this.handleBookingDispath()}>Dispatch</button>
                                        <button type="button" class="btn btn-primary mr-4" onClick={() => this.handleBookingUpdate()}>Update</button>
                                        <button type="button" class="btn btn-primary mr-4" onClick={() => this.handleBulkSMS()}>Send Bulk SMS</button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer autoClose={3000} />
            </div>
        )
    }
};