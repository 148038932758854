import React, {useState, useEffect} from "react";
// import { Link } from "@reach/router";
import { auth, signInWithGoogle} from "../../service/firebase";
import {authList} from "../../config"
import Admin from "../../components/admin"
import {AdminContent} from "../../content"

const SignIn = () => {
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [isAdminVisible, showAdmin] = useState(false);
    const [isAuthFromGoogle, authGoogle] = useState(null);

    useEffect(() => {
      auth.signOut();
    });

    useEffect(() => {
      if(isAuthFromGoogle) {
        auth.onAuthStateChanged(userAuth => {
            setUser(userAuth);
            authGoogle(false);
            if(userAuth) {
              if(authList.includes(userAuth.email)) {
                  showAdmin(true);
                } else {
                  setError(AdminContent.signinError);
              }
            }
        });
      }
    }, [isAuthFromGoogle]);

  const handleGoogleSignIn = (event) => {
    setError(null);
    showAdmin(false);
    authGoogle(true);
    signInWithGoogle(event);
  }

  return (
    <div className={`container mt-5 mb-5 ${(!isAdminVisible)? "pageHeightBody" : null}`}>
      {
        (error)?
        <div class="alert alert-danger" role="alert">{error}!</div>
        :
        null
      }
      {
        (isAdminVisible)?
        <Admin/>
        :
        <>
          <h1 className="text-3xl mb-2 text-center font-bold">Sign In</h1>
          <div className="row justify-content-center">
            <div 
              class="btn btn-lg btn-google btn-block text-uppercase btn-outline col-md-6" 
              onClick = {(event) => handleGoogleSignIn(event)}>
              <img src="https://img.icons8.com/color/16/000000/google-logo.png"/> 
              Signin Using Google
            </div>
          </div>
        </>
      }
    </div>
  );
};
export default SignIn;