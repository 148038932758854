import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import {Navbar, Nav, Container} from 'react-bootstrap'
import {HeroContent} from '../../content'
import logo from '../../assets/images/logo.jpg'


export default class Header extends Component {
    render() {
        return (
            <header>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} width="50" className="mr-2" />
                        Smart Chauffeur
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Link>
                                <Link to="/" className="btn btn-link text-white">Home</Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link to="/aboutus" className="btn btn-link text-white">About Us</Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link to="/packages" className="btn btn-link text-white">Packages</Link>
                            </Nav.Link>
                                <a href={`tel: ${HeroContent.number}`} className="header-btn p-3 text-dark btn-light text-decoration-none">Call Now</a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
                </Navbar>
            </header>
        )
    }
}