import React, { Component } from 'react'
import {Jumbotron, Container} from 'react-bootstrap'
import {HeroContent} from '../../content'

export default class Hero extends Component {
    render() {
        return (
            <Jumbotron className="jumboWIthBackground" fluid>
                <Container>
                    <h1 className="text-white font-weight-bold callNow">{HeroContent.title}</h1>
                    <h1 className="text-white font-weight-bold numText"><a href={`tel: ${HeroContent.number}`} className="text-white text-decoration-none">{HeroContent.number}</a></h1>
                </Container>
            </Jumbotron>
        )
    }
}
