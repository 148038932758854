export default {
    blogOne: {
        title: "A company Respecting your privacy",
        desc: "Our drivers understand the importance of protecting and respecting clients’ privacy. We are dedicated, honest and extensively trustworthy. We are commited to maintain  your peace of mind during night time travels, ensuring secure rides with our instructed, trained staff ", 
        img: "../../assets/images/blog1img.jpg"
    },
    blogTwo: {
        title: "Our chauffeur drivers are Experienced and Professional",
        desc: "Reliability implies that the chauffeur will be at the pick-up location on-time. Our priority is to make your chauffeur car ride more than just delivering you to a certian location, but protect and a stress free ride. A good customer service relationship with our well experience professionals. You can call our 24/7 Hotline to reserve your next bookin. ",
        para1: "You can call our 24/7 Hotline to reserve your next booking",
        img: "../../assets/images/blogImg.jpg"
    },
}