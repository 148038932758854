import React, { Component } from 'react'
import Hero from '../../components/hero'
import {HomeContent} from '../../content'
import blogOne from '../../assets/images/blog1img.jpeg';
import blogTwo from '../../assets/images/blog2img.jpg';

export default class Home extends Component {
    render() {
        return (
            <>
                <Hero/>
                <div className="container">

                    <div class="row featurette">
                        <div class="col-md-7">
                            <h2 class="featurette-heading">{HomeContent.blogOne.title}</h2>
                            <p class="lead">{HomeContent.blogOne.desc}</p>
                        </div>
                        <div class="col-md-5">
                            <img src={blogOne} class="img-fluid" />
                        </div>
                    </div>

                    <hr class="featurette-divider"/>

                    <div class="row featurette">
                        <div class="col-md-7 order-md-2">
                            <h2 class="featurette-heading">{HomeContent.blogTwo.title}</h2>
                            <p class="lead">{HomeContent.blogTwo.desc}</p>
                        </div>
                        <div class="col-md-5 order-md-1">
                            <img src={blogTwo} class="img-fluid" />
                        </div>
                    </div>

                    <hr class="featurette-divider"/>
                </div>
            </>
        )
    }
}
